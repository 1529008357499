import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addUser, updateUserDetails } from "../../../redux/slices/web";
import { toast } from "react-toastify";
import DatePicker from "react-date-picker";

const EditAccount = (props) => {
  const dispatch = useDispatch();
  const [isTermConditionChecked, setIsTermConditionChecked] = useState(false);
  const [Name, setName] = useState(props?.editUsersDetails?.fullName);
  const [userName, setUserName] = useState(props?.editUsersDetails?.userName);
  const [email, setEmail] = useState("");
  const [linkToWebsite, setLinkToWebsite] = useState("");
  const [country, setCountry] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [accountType, setAccountType] = useState(
    props?.editUsersDetails?.accountType
  );
  const [businessName, setBusinessName] = useState(
    props?.editUsersDetails?.businessName
  );

  const addUserDetail = () => {
    if (props?.flag == "editUser") {
      if (!userName) {
        toast.error("Please enter user Name");
        return;
      }

      let param = {
        id: props?.editUsersDetails?._id,
        userName: userName,
      };
      if (accountType == "individual") {
        if (!Name) {
          toast.error("Please enter full Name");
          return;
        }
        param.fullName = Name;
      }
      if (accountType == "business") {
        if (!businessName) {
          toast.error("Please enter bussiness Name");
          return;
        }
        param.businessName = businessName;
      }
      dispatch(
        updateUserDetails({
          ...param,
          cb(res) {
            if (res) {
              props?.getAllAccountsInformation();
              props?.close();
            }
          },
        })
      );
    } else {
      const URL_REGEX = /^(ftp|http|https):\/\/[^ "]+$/;
      let specialCharacter =
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
          password
        );

      if (!accountType) {
        toast.error("Please select account type");
        return;
      }
      if (accountType == "business" && !businessName) {
        toast.error("Please enter bussiness Name");
        return;
      }
      if (accountType === "individual" && !Name) {
        toast.error("Please enter full Name");
        return;
      } else if (!userName) {
        toast.error("Please enter user Name");
        return;
      } else if (!email) {
        toast.error("Please enter email");
        return;
      } else if (
        email &&
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        toast.error("Please enter valid email address");
        return;
      } else if (accountType === "individual" && !dateOfBirth) {
        toast.error("Please enter Date of Birth");
        return;
      } else if (accountType == "business" && !linkToWebsite) {
        toast.error("Please enter website link");
        return;
      } else if (accountType == "business" && !URL_REGEX.test(linkToWebsite)) {
        toast.error("Please enter correct website link");
        return;
      } else if (accountType === "individual" && !userAddress) {
        toast.error("Please enter  Address");
        return;
      } else if (accountType == "business" && !country) {
        toast.error("Please enter Country");
        return;
      } else if (!password) {
        toast.error("Please enter Passwords");
        return;
      } else if (password.length <= 7) {
        toast.error("Password should be maximum 8 character");
        return;
      } else if (!specialCharacter) {
        toast.error(
          "Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character"
        );
        return;
      } else if (!confirmPassword) {
        toast.error("Please enter confirm Passwords");
        return;
      } else if (password !== confirmPassword) {
        toast.error("Passwords do not match");
        return;
      } else if (accountType == "business" && !isTermConditionChecked) {
        toast.error(
          "Please agree to our terms and conditions to proceed further."
        );
        return;
      }

      let params = {
        password: confirmPassword,
        email: email,
        userName: userName,
        accountType: accountType,
        address: {
          coordinates: [30.3633, 69.36544],
          city: userAddress ? userAddress : country,
          state: userAddress ? userAddress : country,
          country: userAddress ? userAddress : country,
        },
      };
      if (accountType === "individual") {
        params.fullName = Name;
        params.dob = dateOfBirth;
      }
      if (accountType == "business") {
        params.webLink = linkToWebsite;
        params.businessName = businessName;
      }
      dispatch(
        addUser({
          ...params,
          cb(res) {
            if (res) {
              props?.getAllAccountsInformation();
              props?.close();
            }
          },
        })
      );
    }
  };

  //onchange input
  const handleTermConditionChange = (fieldname) => {
    if (fieldname === "termCondition") {
      setIsTermConditionChecked(!isTermConditionChecked);
    }
  };

  return (
    <form>
      <div className="row">
        {props?.flag == "addUser" ? (
          <div class="mb-3">
            <label for="name" class="form-label">
              Account Type
            </label>
            <select
              class="form-select"
              aria-label="Default select example"
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
            >
              <option selected>Open this select Account Type</option>
              <option value="individual">Individual</option>
              <option value="business">Business</option>
            </select>
          </div>
        ) : (
          ""
        )}

        {accountType == "individual" && props?.flag ? (
          <div class="mb-3">
            <label for="name" class="form-label">
              Full Name
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="First Name"
              value={Name ? Name : ""}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        ) : (
          ""
        )}

        {accountType == "business" && props?.flag ? (
          <div class="mb-3">
            <label for="name" class="form-label">
              Business Name
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="business Name"
              value={businessName ? businessName : ""}
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </div>
        ) : (
          ""
        )}

        {accountType && props?.flag ? (
          <div class="mb-3">
            <label for="name" class="form-label">
              User Name
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="user Name"
              value={userName ? userName : ""}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
        ) : (
          ""
        )}

        {props?.flag == "addUser" && accountType ? (
          <div class="mb-3">
            <label for="name" class="form-label">
              Email
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        ) : (
          ""
        )}

        {accountType == "business" && props?.flag == "addUser" ? (
          <>
            <div class="mb-3">
              <label for="name" class="form-label">
                Link to Website
              </label>
              <input
                type="text"
                class="form-control"
                // id="name"
                placeholder="Link to Website"
                value={linkToWebsite}
                onChange={(e) => setLinkToWebsite(e.target.value)}
              />
            </div>

            <div class="mb-3">
              <label for="name" class="form-label">
                Country of Origin
              </label>
              <input
                type="text"
                class="form-control"
                // id="name"
                placeholder="Country of Origin"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
          </>
        ) : (
          ""
        )}

        {accountType == "individual" && props?.flag == "addUser" ? (
          <>
            <div class="mb-3">
              <label for="name" class="form-label">
                Date Of Birth
              </label>
              <input
                type="date"
                class="form-control"
                // id="name"
                placeholder="Date Of Birth"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>

            <div class="mb-3">
              <label for="name" class="form-label">
                City State Country
              </label>
              <input
                type="text"
                class="form-control"
                // id="name"
                placeholder="City State Country"
                value={userAddress}
                onChange={(e) => setUserAddress(e.target.value)}
              />
            </div>
          </>
        ) : (
          ""
        )}

        {props?.flag == "addUser" && accountType ? (
          <>
            <div class="mb-3 password-input">
              <label for="name" class="form-label">
                Password
              </label>
              <div className="position-relative">
              <input
                type={passwordShown ? "text" : "password"}
                class="form-control"
                // id="name"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="toggle_password_ info_icon" onClick={() => { setPasswordShown(!passwordShown) }}>
                  <span className={passwordShown ? "show-icon" : "hide-icon"} ></span>
                </span>
                </div>
            </div>

            <div class="mb-3">
              <label for="name" class="form-label">
                Confirm Password
              </label>
              <div className="position-relative">
              <input
                type={confirmPasswordShown ? "text" : "password"}
                class="form-control"
                id="name"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span className="toggle_password_ info_icon" onClick={() => { setConfirmPasswordShown(!confirmPasswordShown) }}>
                  <span className={confirmPasswordShown ? "show-icon" : "hide-icon"} > </span>
                </span>
                </div>
            </div>
          </>
        ) : (
          ""
        )}

        {accountType == "business" && props?.flag == "addUser" ? (
          <div class="mb-3">
            <input
              type="checkbox"
              id="accpt"
              className="checkBox me-2"
              onChange={(e) => {
                handleTermConditionChange("termCondition");
              }}
            />
            <label htmlFor="accpt" className="form-label d-inline">
              <span>
                I agree with the Term and Conditions and the Privacy Policy
              </span>
            </label>
          </div>
        ) : (
          ""
        )}
      </div>
      <div class="mb-3 text-center border-top ">
        <button
          className="tablebtn mt-3 py-2 px-3"
          type="button"
          onClick={() => {
            addUserDetail();
          }}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default EditAccount;
