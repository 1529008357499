import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,

};

export const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    getAccountDetails: (state) => {
      state.loading = true;
    },
    setGetAccountDetails: (state) => {
      state.loading = false;
    },
    getAllUser: (state) => {
      state.loading = true;
    },
    setGetAllUser: (state, action) => {
      state.loading = false;
    },
    updateActiveInactiveStatus: (state) => {
      state.loading = true;
    },
    setUpdateActiveInactiveStatus: (state, action) => {
      state.loading = false;
    },
    updateUserDetails: (state) => {
      state.loading = true;
    },
    setUpdateUserDetails: (state, action) => {
      state.loading = false;
    },
    addUser: (state) => {
      state.loading = true;
    },
    setAddUser: (state, action) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getAccountDetails,
  setGetAccountDetails,
  getAllUser,
  setGetAllUser,
  updateActiveInactiveStatus,
  setUpdateActiveInactiveStatus,
  updateUserDetails,
  setUpdateUserDetails,
  addUser,
  setAddUser,
  onErrorStopLoad,
} = webSlice.actions;

export default webSlice.reducer;
