import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  onErrorStopLoad,
  getAllUser,
  updateActiveInactiveStatus,
} from "../../../redux/slices/web";
import ReactPaginate from "react-paginate";
import CustomModal from "../../Modal/CustomModal";
import * as images from "../../../utilities/images";
import EditAccount from "./editAccount";

const ManageAccounts = () => {
  const dispatch = useDispatch();
  const [key, setKey] = useState(Math.random());
  const [usersDataInfo, setUsersDataInfo] = useState([]);
  const [active, setActive] = useState("");
  const [accountType, setAccountType] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [editUsersDetails, setEditUsersDetails] = useState("");
  const [flag, setFlag] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  // getting all account information
  const getAllAccountsInformation = (page = currentPage) => {
    let params = {
      accountType: accountType,
      status: active,
      limit: 10,
      page: page,
      search: search,
    };
    setLoading(true);
    dispatch(
      getAllUser({
        ...params,
        cb(res) {
          setLoading(false);
          if (res.status === 200) {
            setUsersDataInfo(res?.data?.data?.data);
            setPageCount(res.data.data.total_pages);
          } else {
            setLoading(false);
          }
        },
      })
    );
  };

  // update User Active Inactive Status
  const updateUserActiveInactiveStatus = (id, status) => {
    let params = {
      id: id,
      status: status,
    };
    dispatch(
      updateActiveInactiveStatus({
        ...params,
        cb(res) {
          if (res.status === 200) {
            getAllAccountsInformation();
          }
        },
      })
    );
  };

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
    getAllAccountsInformation(selected + 1);
  };

  const editUserInformation = (data,flag) => {
    setFlag(flag)
    setEditUsersDetails(data)
    setModalDetail({ show: true, flag: "editAccount" });
    setKey(Math.random());
  };

    //closeModal
    const handleOnCloseModal = () => {
      setModalDetail({
        show: false,
        title: "",
        flag: "",
      });
      setKey(Math.random());
    };

  // stop loader on page reload
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  useEffect(() => {
    getAllAccountsInformation();
  }, [active, accountType, search]);


  return (
    <div className="content-wrapper adminUsers">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Manage Accounts</h1>
            </div>
            <div className="col-sm-6 text-end">
              <button
                className="editcontentBtn"
                onClick={() => {
                  editUserInformation("","addUser")
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <section class="mb-2">
        <div class="container-fluid">
          <div className="row">
            <div class=" mt-4 userFilter">
              <div class="d-flex commonFilterHeader  ">
                <p class="commonBox_Heading m-0">Filter By Status</p>
                <div class="dropdown filterDropdown userListFilter">
                  <button
                    class="filterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-filter"></i>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end dropboxmenu">
                    <li>
                      <a
                        class="dropdown-item font-14 subtext"
                        href="#"
                        onClick={(e) => setActive("")}
                      >
                        All
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item font-14 subtext"
                        href="#"
                        onClick={(e) => setActive("active")}
                      >
                        Active
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item font-14 subtext"
                        href="#"
                        onClick={(e) => setActive("inactive")}
                      >
                        InActive
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex commonFilterHeader  ">
                <p class="commonBox_Heading m-0">Filter By Account Type</p>
                <div class="dropdown filterDropdown userListFilter ">
                  <button
                    class="filterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-filter"></i>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end dropboxmenu">
                    <li>
                      <a
                        class="dropdown-item font-14 subtext"
                        href="#"
                        onClick={(e) => setAccountType("")}
                      >
                        All
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item font-14 subtext"
                        href="#"
                        onClick={(e) => setAccountType("individual")}
                      >
                        individual
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item font-14 subtext"
                        href="#"
                        onClick={(e) => setAccountType("business")}
                      >
                        business
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex commonFilterHeader  userSearch ">
                <input
                  type="search"
                  class="filterSearch"
                  placeholder="search..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="content commonBox_  userlistbox">
        <div class="container-fluid">
          <table class="usertable">
            <tr class="tableborder">
              <th>Name</th>
              <th>User Name</th>
              <th>Email</th>
              <th>Business Name</th>
              <th>Country</th>
              <th>Account Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan={9}>
                    <div className="loaderOuter d-flex justify-content-center ">
                      <div className="spinner-grow " role="status"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                {usersDataInfo && usersDataInfo.length > 0 ? (
                  <>
                    {usersDataInfo?.map((data, index) => (
                      <tr key={index}>
                        <td>{data?.fullName ? data?.fullName : "---"}</td>
                        <td>{data?.userName}</td>
                        <td class="text-lowercase">{data?.email}</td>
                        <td>{data?.businessName ? data?.businessName : "---"}</td>
                        <td>{data?.address?.country}</td>
                        <td>{data?.accountType}</td>
                        <td
                          className={
                            data?.status === "Active"
                              ? "activeBtn"
                              : "inactivebtn"
                          }
                        >
                          {data?.status}
                        </td>
                        <td>
                          <div class="dropdown filterDropdown">
                            <button
                              class="barbtn"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i class="fa fa-ellipsis-v"></i>
                            </button>
                            <button
                              class="modalbtn ms-3 barbtn"
                              onClick={() => {
                                editUserInformation(data,"editUser");
                              }}
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a
                                  class="dropdown-item font-14 userText"
                                  onClick={() => {
                                    updateUserActiveInactiveStatus(
                                      data?._id,
                                      "Active"
                                    );
                                  }}
                                >
                                  Active
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item font-14 userText"
                                  onClick={() => {
                                    updateUserActiveInactiveStatus(
                                      data?._id,
                                      "Inactive"
                                    );
                                  }}
                                >
                                  Inactive
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <p className="p-2">No data found</p>
                )}
              </>
            )}
          </table>
          <div className="paginationMain_ mt-4">
            {usersDataInfo && usersDataInfo?.length > 0 && (
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                pageCount={pageCount}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </section>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "editAccount" ? "commonWidth customContent" : ""
        }
        ids={modalDetail.flag === "editAccount" ? "tablemodalbtn" : ""}
        child={ modalDetail.flag === "editAccount" ? (
            <EditAccount
            flag={flag}
            getAllAccountsInformation={() => {getAllAccountsInformation()}}
            editUsersDetails={editUsersDetails}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={modalDetail.flag === "editAccount" ? (
            <>
              <h2 className="modal_Heading">{flag == "addUser"? "Add Account":"Edit Account"}</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCancel}
                  className="ModalCancel"
                  alt="modalcancel"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default ManageAccounts;
