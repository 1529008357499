import React, { useEffect, useState } from "react";
import {
  getHelperPages,
  onErrorStopLoad,
  updateHelperPageStatus,
} from "../../redux/slices/web";
import { useDispatch } from "react-redux";
import { useWebSelector } from "../../redux/selector/web";
import moment from "moment";
import { Link } from "react-router-dom";
import * as images from "../../utilities/images";

const ManageOutfit = () => {
  const dispatch = useDispatch();
  const webData = useWebSelector();
  const [key, setKey] = useState(Math.random());
  const [helperPageData, setHelperPageData] = useState([]);
  const [showContent, setShowContent] = useState({
    title: "",
    content: "",
    id: "",
  });
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // close modal
  const handleOpenModal = (flag, title, content, id, slug) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
    setShowContent({
      title: title,
      content: content,
      id: id,
      slug: slug,
    });
  };
  // get content
  useEffect(() => {
    getHelperPagesContent();
  }, []);

  const getHelperPagesContent = () => {
    // dispatch(
    //   getHelperPages({
    //     cb(res) {
    //       if (res.status === 200) {
    //         setHelperPageData(res.data.data.data);
    //       }
    //     },
    //   })
    // );
  };

  // update helper pages status
  const updatePageStatus = (id, status) => {
    // let params = {
    //   id: id,
    //   active: status,
    // };
    // dispatch(
    //   updateHelperPageStatus({
    //     ...params,
    //     cb(res) {
    //       if (res.status === 200) {
    //         getHelperPagesContent();
    //       }
    //     },
    //   })
    // );
  };

  // stop loading on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Manage Outfit</h1>
            </div>
          </div>
        </div>
      </div>
      {/* Main content */}

    </div>
  );
};

export default ManageOutfit;
