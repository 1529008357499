import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAccountDetails, onErrorStopLoad } from "../../redux/slices/web";

const Dashboard = () => {
  document.title = "Home";
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState([]);

  // get dashboard data
  useEffect(() => {
    dispatch(
      getAccountDetails({
        cb(res) {
          if (res.status === 200) {
            setDashboardData(res.data.data);
          }
        },
      })
    );
  }, []);

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <div className="content-wrapper ">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-6">
              <div class="small-box dashboxcolor">
                  <div class="inner">
                    <h3>{dashboardData?.usersCount}</h3>
                    <p>Users</p>
                  </div>
                  <div class="icon"><i class="ion ion-person-add"></i></div>
                  <a class="small-box-footer"><i class="fas "></i></a>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="small-box dashboxcolor_two">
                  <div class="inner">
                    <h3>{dashboardData?.individualUser}</h3>
                    <p>Individual</p>
                  </div>
                  <div class="icon"><i class="fas fa-bread-slice"></i> </div>
                  <a class="small-box-footer"><i class="fas "></i></a>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <div class="small-box bg-warning dashthree">
                  <div class="inner">
                    <h3>{dashboardData?.businessUser}</h3>
                    <p>Business</p>
                  </div>
                  <div class="icon"><i class="fas fa-bread-slice"></i></div>
                  <a class="small-box-footer"><i class="fas "></i></a>
              </div>
            </div>
        </div>
      </div>
 
    </div>
  );
};


export default Dashboard;
