import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  setUserChefList,
  setUpdateStatus,
  onErrorStopLoad,
  setVerifyDocument,
  setGetHelperPages,
  setUpdateHelperPages,
  setGetSingleHelperPage,
  setUpdateHelperPageStatus,
  setDeleteHelperPage,
  setgetStats,
  setGetAllUser,
  setUpdateActiveInactiveStatus,
  setGetAccountDetails,
  setUpdateUserDetails,
  setAddUser,
} from "../../slices/web";

function* getAccountDetails(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_ACCOUNT_INFO}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setGetAccountDetails(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}


function* getAllUser(action) {
  let mainUrl = `${ApiPath.webApiPath.GET_ALL_USERS}?limit=${action.payload.limit}&page=${action.payload.page}&`;
  if (action.payload.accountType) {
    mainUrl += `accountType=${action.payload.accountType}&`;
  }
  if (action.payload.status) {
    mainUrl += `status=${action.payload.status}&`;
  }
  if (action.payload.search) {
    mainUrl += `search=${action.payload.search}&`;
  }

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = mainUrl),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      yield put(setGetAllUser(action.payload));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* updateActiveInactiveStatus(action) {
  const paramToSend = { ...action.payload };
  delete paramToSend.id;

  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.webApiPath.UPDATE_ACTIVE_INACTIVE_STATUS}/${action.payload.id}`),
      (action.payload = paramToSend)
    );
    if (resp.status) {
      yield put(setUpdateActiveInactiveStatus(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* updateUserDetails(action) {
  const paramToSend = { ...action.payload };
  delete paramToSend.id;

  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.webApiPath.UPDATE_ACCOUNT_INFO}/${action.payload.id}`),
      (action.payload = paramToSend)
    );
    if (resp.status) {
      yield put(setUpdateUserDetails(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* addUser(action) {
  const paramToSend = { ...action.payload };
  delete paramToSend.id;

  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.webApiPath.ADD_USER}`),
      (action.payload = paramToSend)
    );
    if (resp.status) {
      yield put(setAddUser(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* webSaga() {
  
  yield all([takeLatest("web/getAccountDetails", getAccountDetails)]);
  yield all([takeLatest("web/getAllUser", getAllUser)]);
  yield all([takeLatest("web/updateActiveInactiveStatus", updateActiveInactiveStatus)]);
  yield all([takeLatest("web/updateUserDetails", updateUserDetails)]);
  yield all([takeLatest("web/addUser", addUser)]);
}

export default webSaga;
