const ApiPath = {
  AuthApiPath: {
    ADMIN_LOGIN: "/api/v1/users/sign-in",
    FORGOT_PASSWORD: "/api/v1/users/forgot-password",
    RESET_PASSWORD: "/api/v1/users/reset-password",
    LOGOUT_ADMIN: "/api/v1/users/logout",
    ENTER_OTP: "/api/v1/users/verify-otp",
    RSEND_ENTER_OTP: "/api/v1/users/resend-otp",
  },
  webApiPath: {
    GET_ALL_USERS: "/api/v1/users/all",
    UPDATE_ACTIVE_INACTIVE_STATUS: "/api/v1/users/status",
    GET_ACCOUNT_INFO: "/api/v1/users/dashboard",
    UPDATE_ACCOUNT_INFO:"/api/v1/users",
    ADD_USER:"/api/v1/users"
  },
};

export default ApiPath;
