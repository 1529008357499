import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetPassword, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";
import * as images from "../../utilities/images";

const ResetPassword = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let email = location?.state?.email;
  const authData = useAuthSelector();
  const [errorMessages, setErrorMessages] = useState({});
  const [formData, setFormData] = useState({
    password: "",
    confirmpassword: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  // validation of input fields
  const validateForm = () => {
    var specialCharacter =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
      formData.password
    );
    let errors = {};
    let formIsValid = true;

    if (!formData.password) {
      formIsValid = false;
      errors = { ...errors, password: "Please enter your password." };
    } else if (!specialCharacter) {
      formIsValid = false;
      errors = {
        ...errors,
        password: "Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character",
      };
    }
    else if (!formData.confirmpassword) {
      formIsValid = false;
      errors = {
        ...errors,
        confirmpassword: "Please enter your confirm password.",
      };
    }
    else if (formData.password !== formData.confirmpassword) {
      formIsValid = false;
      errors = {
        ...errors,
        confirmpassword: "Passwords do not match.",
      };
    }

    setErrorMessages(errors);
    return formIsValid;
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = {
        email: email,
        password: formData.confirmpassword,
      };
      dispatch(
        resetPassword({
          ...params,
          cb(ress) {
            if (ress.status === 200) {
              navigate("/login");
            }
          },
        })
      );
    }
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="login-page authBg resetpwd">
          <div className="card card-outline card-primary cardTopOutline cardBg">
            <div className="loginPage">
            <div className="d-flex align-items-center justify-content-center">
            <img
              src={images.OrigoLogo}
              className="brand_logo"
              alt="Brand Logo"
              style={{ width: "200px" }}
            />
          </div>
              <div className="card-header text-center">
                <h3 className="mb-0 logintext">Reset Password</h3>
              </div>
              <div className="card-body login-card-body">
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >

                  <div className="input-container ">
                    <input
                      type="password"
                      className="form-control form-input borderinput"
                      name="password"
                      placeholder="enter your password"
                      value={formData.password}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <label for="username" className="form-label d-block inputBox">
                      Password{" "}
                    </label>
                    <span className="error invalid-feedback">
                      {errorMessages.password}
                    </span>
                  </div>

                  <div className="input-container mt-4 mb-2">
                    <input
                      type="password"
                      className="form-control form-input borderinput"
                      name="confirmpassword"
                      placeholder="Password"
                      value={formData.confirmpassword}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <label for="username" className="form-label d-block inputBox">
                      Confirm Password
                    </label>
                    <span className="error invalid-feedback">
                      {errorMessages.confirmpassword}
                    </span>
                  </div>

                  <div className="row">
                    <div className="col-12 text-center">
                      <button
                        className="loginBtnCommon btnYellow mt-5 mw-100 loginbtn"
                        type="submit"
                      >
                        {authData.loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Reset Password</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
    </>
  );
};

export default ResetPassword;
